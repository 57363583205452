#toolbar{
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #fff;
  border-top: 1px solid #e1e1e1;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 100;
}

@media screen and (min-width: 768px) {
  #toolbar {
    position: static;
    left: auto;
    right: auto;
    bottom: auto;
  }
}

.rbt-input-multi.form-control.rbt-input {
  padding: 3px 3px 0;
}

.status-loading {
  opacity: .5;
}

.status-success.card {
  border: 2px solid #fff;
  background: #fff;
  box-shadow: #0004 0 2px 6px;
}

.status-failed.card {
  border-color: #dfa0bb;
  background: #FCE4EC;
}

button.x {
  border: 0;
  padding: 6px 8px;
  box-shadow: none;
  background: #c3c3c3;
  border-radius: 25px;
  font-size: 15px;
  line-height: 14px;
}